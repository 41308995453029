import React from "react";
import novostiImage from "../assets/images/novosti.jpg";
import apartmaniImage from "../assets/images/apartmani.png";
import { Link } from "react-router-dom";
import "../styles/Home.scss";

const Home = () => {
  return (
    <main className="page home">
      <div className="home-nav">
        <div className="home-nav-item">
          <img src={apartmaniImage} alt="Apartmani" />
          <h2>Apartmani</h2>
          <p>
            Pregledajte i uređujte apartmane koji su trenutno dostupni na
            stranici.
          </p>
          <Link className="btn" to="/apartments">
            Uredi apartmane
          </Link>
        </div>
        <div className="home-nav-item">
          <img src={novostiImage} alt="Novosti" />
          <h2>Novosti</h2>
          <p>
            Pregledajte i uređujte novosti koje su trenutno dostupne na
            stranici.
          </p>
          <Link className="btn" to="/news">
            Uredi novosti
          </Link>
        </div>{" "}
      </div>
    </main>
  );
};

export default Home;
