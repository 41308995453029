import React, { useRef, useEffect, useState } from "react";
import { useAppContext } from "../AppContext";
import PageTitle from "../components/PageTitle";
import DatePicker from "react-datepicker";
import { format, parse, parseISO } from "date-fns";
import { hr } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/Page.scss";
import { serverUrl } from "../utils/domainName";
import LoadingSpinner from "../components/LoadingSpinner";
import ApartmentsTable from "../components/ApartmentsTable";
import { useTranslation } from "react-i18next";

const Apartments = () => {
  const { t } = useTranslation();
  const { apartments, setApartments } = useAppContext();
  const [uploadingImages, setUploadingImages] = useState(false);
  const [uploadingFloorPlan, setUploadingFloorPlan] = useState(false);
  const [activeApartment, setActiveApartment] = useState({
    title: {
      hr: "",
      en: "",
    },
    content: {
      hr: "<div></div>",
      en: "<div></div>",
    },
    description: {
      hr: "",
      en: "",
    },
    images: [],
    dateBuiltStarted: "",
    dateBuiltEnds: "",
    price: "",
    floor: "",
    quadraturesPerRoom: [],
    floorPlan: "",
  });
  const [newQuadratureItem, setNewQuadratureItem] = useState({
    id: "",
    room: "",
    quadrature: "",
    coeficient: "",
  });
  const [imagesLinks, setImagesLinks] = useState([]);
  const [selectedTag, setSelectedTag] = useState("DNEVNI BORAVAK");

  const activeItemtRef = useRef();

  useEffect(() => {
    fetchApartments();
  }, []);

  useEffect(() => {
    // scroll the component to the 0
    activeItemtRef.current.scrollIntoView({ behavior: "smooth" });
  }, [activeApartment?._id]);

  const fetchApartments = () => {
    fetch(`${serverUrl}api/apartments`)
      .then((res) => res.json())
      .then((data) => {
        setApartments(data);
      })
      .catch((err) => {
        fetchApartments();
      });
  };

  const addNewFunction = () => {
    setActiveApartment({
      title: {
        hr: "",
        en: "",
      },
      content: {
        hr: "<div></div>",
        en: "<div></div>",
      },
      description: {
        // Corrected from 'descritpion'
        hr: "",
        en: "",
      },
      images: [],
      dateBuiltStarted: "",
      dateBuiltEnds: "",
      price: "",
      floor: "",
      quadraturesPerRoom: [],
      floorPlan: "",
    });
  };

  const searchFunction = () => {
    console.log("Search apartment");
  };

  const addQuadratureItem = () => {
    if (newQuadratureItem.room === "") {
      setNewQuadratureItem({
        ...newQuadratureItem,
        room: roomsQuadraturesOptions[0],
      });
    }
    if (
      newQuadratureItem.id === "" ||
      newQuadratureItem.quadrature === "" ||
      newQuadratureItem.coeficient === ""
    ) {
      alert("Molimo unesite sve podatke");
      return;
    }
    setActiveApartment({
      ...activeApartment,
      quadraturesPerRoom: [
        ...activeApartment.quadraturesPerRoom,
        { ...newQuadratureItem },
      ],
    });
    setNewQuadratureItem({
      id: "",
      room: "",
      quadrature: "",
      coeficient: "",
    });
  };

  const updateQuadratureItem = (index, field, value) => {
    const updatedItems = [...activeApartment.quadraturesPerRoom];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: value,
    };
    setActiveApartment({
      ...activeApartment,
      quadraturesPerRoom: updatedItems,
    });
  };

  const removeQuadratureItem = (index) => {
    const updatedItems = [...activeApartment.quadraturesPerRoom];
    updatedItems.splice(index, 1);
    setActiveApartment({
      ...activeApartment,
      quadraturesPerRoom: updatedItems,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");

      if (activeApartment._id) {
        fetch(`${serverUrl}api/apartments/${activeApartment._id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(activeApartment),
        })
          .then((res) => res.json())
          .then((data) => {
            const updatedApartments = apartments.map((apartment) => {
              if (apartment._id === data._id) {
                return data;
              }
              return apartment;
            });

            setApartments(updatedApartments);
            setActiveApartment({
              title: {
                hr: "",
                en: "",
              },
              content: {
                hr: "<div></div>",
                en: "<div></div>",
              },
              description: {
                hr: "",
                en: "",
              },
              images: [],
              dateBuiltStarted: "",
              dateBuiltEnds: "",
              price: "",
              floor: "",
              quadraturesPerRoom: [],
              floorPlan: "",
            });
          })
          .catch((err) => {
            console.error("Error updating apartment:", err);
          });
      } else {
        fetch(`${serverUrl}api/apartments`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(activeApartment),
        })
          .then((res) => res.json())
          .then((data) => {
            setApartments([...apartments, data]);
            setActiveApartment({
              title: {
                hr: "",
                en: "",
              },
              content: {
                hr: "<div></div>",
                en: "<div></div>",
              },
              description: {
                hr: "",
                en: "",
              },
              images: [],
              dateBuiltStarted: "",
              dateBuiltEnds: "",
              price: "",
              floor: "",
              quadraturesPerRoom: [],
              floorPlan: "",
            });
          })
          .catch((err) => {
            console.error("Error creating apartment:", err);
          });
      }
    } catch (error) {
      console.error("Date parsing error:", error);
    }
  };

  const deleteImage = (image) => {
    const updatedImages = activeApartment.images.filter(
      (img) => img.url !== image.url
    );
    setActiveApartment({
      ...activeApartment,
      images: updatedImages,
    });
    deleteImageApi(image.url);
  };

  const deleteImageApi = (url) => {
    fetch(url, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {})
      .catch((err) => {
        deleteImageApi(url);
      });
  };

  const imageTagsOptions = [
    "DNEVNI BORAVAK",
    "KUHINJA",
    "KUPAONICA",
    "TERASA",
    "SPAVAĆA SOBA",
    "ULAZ",
    "EKSTERIJER",
    "OSTALO",
  ];

  const addImagesWithTags = () => {
    // add imagesLinks to activeApartment.images with selectedTag
    // image.url = imagesLinks[i] and image.tag = selectedTag

    const images = imagesLinks.map((url) => {
      return { url, tag: selectedTag };
    });

    setActiveApartment({
      ...activeApartment,
      images: [...activeApartment.images, ...images],
    });

    setImagesLinks([]);
  };

  const uploadImages = (e) => {
    setUploadingImages(true);

    setImagesLinks([]);
    const files = e.target.files;

    if (files.length === 0) {
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]); // Make sure the field name matches 'files' in your Multer configuration
    }

    fetch(`${serverUrl}api/upload`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const links = data.keys.map((key) => {
          return `${serverUrl}api/upload/${key}`;
        });
        setImagesLinks(links);
        setUploadingImages(false);
      })
      .catch((err) => {
        alert(
          "Greška prilikom uploada slika, pokušajte ponovno ili probajte smanjiti veličinu slika."
        );
        setUploadingImages(false);
      });
  };

  const uploadFloorPlan = (e) => {
    setUploadingFloorPlan(true);
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("files", file);

    fetch(`${serverUrl}api/upload/`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const link = `${serverUrl}api/upload/${data.keys[0]}`;
        setActiveApartment({
          ...activeApartment,
          floorPlan: link,
        });
        setUploadingFloorPlan(false);
      })
      .catch((err) => {
        alert(
          "Greška prilikom uploada slike, pokušajte ponovno ili probajte smanjiti veličinu slike."
        );
        setUploadingFloorPlan(false);
      });
  };

  const deleteApartment = (apartment) => {
    return () => {
      const token = localStorage.getItem("token");

      fetch(`${serverUrl}api/apartments/${apartment._id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const updatedApartments = apartments.filter(
            (ap) => ap._id !== apartment._id
          );
          setApartments(updatedApartments);
        })
        .catch((err) => {
          deleteApartment(apartment);
        });
    };
  };

  const editApartment = (apartment) => {
    const dateBuiltEnds = apartment.dateBuiltEnds
      ? parseISO(apartment.dateBuiltEnds)
      : null;
    const dateBuiltStarted = apartment.dateBuiltStarted
      ? parseISO(apartment.dateBuiltStarted)
      : null;

    setActiveApartment({
      ...apartment,
      dateBuiltEnds: dateBuiltEnds ? format(dateBuiltEnds, "yyyy-MM-dd") : "",
      dateBuiltStarted: dateBuiltStarted
        ? format(dateBuiltStarted, "yyyy-MM-dd")
        : "",
    });
  };

  const roomsQuadraturesOptions = [
    "ENTRANCE/HALLWAY",
    "LIVING AREA",
    "KITCHEN",
    "BATHROOM",
    "BEDROOM",
    "BEDROOM 1",
    "BEDROOM 2",
    "COVERED TERRACE",
    "OPEN TERRACE",
    "GREEN AREA",
    "2 PARKING SPOTS",
  ];

  return (
    <main className="page apartments-page">
      <div className="items">
        <PageTitle
          title="Apartmani"
          description="Pregledajte, Dodajte i uređujte apartmane koji su trenutno dostupni na stranici."
          addNewFunction={addNewFunction}
          searchFunction={searchFunction}
        />
        <ApartmentsTable
          items={apartments}
          editFunction={editApartment}
          deleteFunction={deleteApartment}
        />
      </div>
      <div className="active-item">
        <div className="active-item-container" ref={activeItemtRef}>
          <div className="active-item-hero">
            <h4>
              {activeApartment.title.hr ? activeApartment.title.hr : "Naslov"}
            </h4>

            {activeApartment.images.length > 0 ? (
              <img
                src={activeApartment.images[0].url}
                alt={activeApartment.title.hr}
              />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            )}
          </div>
          <form className="form" onSubmit={handleSubmit}>
            <div className="form-row">
              <h5>Osnovne informacije</h5>
            </div>
            <div className="form-row">
              <div className="form-row-item">
                <label htmlFor="title-hr">Naslov {"(hr)"}</label>
                <input
                  type="text"
                  id="title-hr"
                  placeholder="Unesite naslov (hr)"
                  value={activeApartment.title.hr}
                  onChange={(e) =>
                    setActiveApartment({
                      ...activeApartment,
                      title: { ...activeApartment.title, hr: e.target.value },
                    })
                  }
                />
              </div>
              <div className="form-row-item">
                <label htmlFor="title-en">Naslov {"(en)"}</label>
                <input
                  type="text"
                  id="title-en"
                  placeholder="Unesite naslov (en)"
                  value={activeApartment.title.en}
                  onChange={(e) =>
                    setActiveApartment({
                      ...activeApartment,
                      title: { ...activeApartment.title, en: e.target.value },
                    })
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-row-item">
                <label htmlFor="date-built-started">
                  Datum početka gradnje
                </label>
                <DatePicker
                  selected={
                    activeApartment.dateBuiltStarted
                      ? parse(
                          activeApartment.dateBuiltStarted,
                          "yyyy-MM-dd",
                          new Date()
                        )
                      : null
                  }
                  onChange={(date) =>
                    setActiveApartment({
                      ...activeApartment,
                      dateBuiltStarted: date ? format(date, "yyyy-MM-dd") : "",
                    })
                  }
                  dateFormat="dd/MM/yyyy"
                  locale={hr}
                  id="date-built-started"
                  placeholderText="Odaberite datum"
                />
              </div>
              <div className="form-row-item">
                <label htmlFor="date-built-ends">Datum završetka gradnje</label>
                <DatePicker
                  selected={
                    activeApartment.dateBuiltEnds
                      ? parse(
                          activeApartment.dateBuiltEnds,
                          "yyyy-MM-dd",
                          new Date()
                        )
                      : null
                  }
                  onChange={(date) =>
                    setActiveApartment({
                      ...activeApartment,
                      dateBuiltEnds: date ? format(date, "yyyy-MM-dd") : "",
                    })
                  }
                  dateFormat="dd/MM/yyyy"
                  locale={hr}
                  id="date-built-ends"
                  placeholderText="Odaberite datum"
                />
              </div>

              <div className="form-row-item">
                <label htmlFor="price">Cijena</label>
                <input
                  type="number"
                  id="price"
                  placeholder="Unesite cijenu"
                  value={activeApartment.price}
                  onChange={(e) =>
                    setActiveApartment({
                      ...activeApartment,
                      price: e.target.value,
                    })
                  }
                />
              </div>
              <div className="form-row-item">
                <label htmlFor="floor">Kat</label>
                <input
                  type="number"
                  id="floor"
                  placeholder="Unesite kat apartmana"
                  value={activeApartment.floor}
                  onChange={(e) =>
                    setActiveApartment({
                      ...activeApartment,
                      floor: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-row-item">
                <label htmlFor="description-hr">Opis {"(hr)"}</label>{" "}
                {/* Corrected from 'descritpion-hr' */}
                <textarea
                  type="text"
                  id="description-hr" // Corrected from 'descritpion-hr'
                  value={activeApartment.description.hr} // Corrected from 'descritpion.hr'
                  placeholder="Unesite opis (hr)"
                  onChange={(e) =>
                    setActiveApartment({
                      ...activeApartment,
                      description: {
                        // Corrected from 'descritpion'
                        ...activeApartment.description, // Corrected from 'descritpion'
                        hr: e.target.value,
                      },
                    })
                  }
                />
              </div>
              <div className="form-row-item">
                <label htmlFor="description-en">Opis {"(en)"}</label>{" "}
                {/* Corrected from 'descritpion-en' */}
                <textarea
                  type="text"
                  id="description-en" // Corrected from 'descritpion-en'
                  value={activeApartment.description.en} // Corrected from 'descritpion.en'
                  onChange={(e) =>
                    setActiveApartment({
                      ...activeApartment,
                      description: {
                        // Corrected from 'descritpion'
                        ...activeApartment.description, // Corrected from 'descritpion'
                        en: e.target.value,
                      },
                    })
                  }
                  placeholder="Unesite opis (en)"
                />
              </div>
            </div>
            <div className="form-row">
              <h5>Kvadrature po sobama</h5>
            </div>
            <div className="form-row">
              <div className="form-row-col">
                <div className="form-row-item">
                  <label htmlFor="room-id">ID sobe</label>
                  <input
                    type="text"
                    id="room-id"
                    placeholder="Unesite ID sobe"
                    value={newQuadratureItem.id}
                    onChange={(e) =>
                      setNewQuadratureItem({
                        ...newQuadratureItem,
                        id: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-row-item">
                  <label htmlFor="room">Soba</label>
                  <select
                    id="room"
                    value={newQuadratureItem.room}
                    onChange={(e) =>
                      setNewQuadratureItem({
                        ...newQuadratureItem,
                        room: e.target.value,
                      })
                    }
                  >
                    {roomsQuadraturesOptions.map((option, index) => {
                      return (
                        <option key={index} value={option}>
                          {t(option)}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-row-item">
                  <label htmlFor="quadrature">Kvadratura</label>
                  <input
                    type="number"
                    id="quadrature"
                    placeholder="Unesite kvadraturu"
                    value={newQuadratureItem.quadrature}
                    onChange={(e) =>
                      setNewQuadratureItem({
                        ...newQuadratureItem,
                        quadrature: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-row-item">
                  <label htmlFor="coeficient">Koeficijent</label>
                  <input
                    type="number"
                    id="coeficient"
                    placeholder="npr. za dnevni boravak 1, za terasu 0.25..."
                    value={newQuadratureItem.coeficient}
                    onChange={(e) =>
                      setNewQuadratureItem({
                        ...newQuadratureItem,
                        coeficient: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="form-row-item">
                  <button
                    className="btn black"
                    type="button"
                    onClick={addQuadratureItem}
                  >
                    Dodaj
                  </button>
                </div>
              </div>
              <div className="form-row-table">
                <div className="form-row-table-header">
                  <div
                    className="form-row-table-entity"
                    style={{ width: "10%" }}
                  >
                    ID
                  </div>
                  <div
                    className="form-row-table-entity"
                    style={{ width: "40%" }}
                  >
                    Soba
                  </div>
                  <div
                    className="form-row-table-entity"
                    style={{ width: "25%" }}
                  >
                    Kvadratura
                  </div>
                  <div
                    className="form-row-table-entity"
                    style={{ width: "15%" }}
                  >
                    Koeficijent
                  </div>
                  <div
                    className="form-row-table-entity"
                    style={{ width: "10%" }}
                  >
                    Akcije
                  </div>
                </div>
                {activeApartment.quadraturesPerRoom.map((item, index) => {
                  return (
                    <div key={index} className="form-row-table-row">
                      <input
                        className="form-row-table-entity"
                        style={{ width: "10%" }}
                        value={item.id}
                        onChange={(e) =>
                          updateQuadratureItem(index, "id", e.target.value)
                        }
                      />
                      <select
                        className="form-row-table-entity"
                        style={{ width: "40%" }}
                        value={item.room}
                        onChange={(e) =>
                          updateQuadratureItem(index, "room", e.target.value)
                        }
                      >
                        {roomsQuadraturesOptions.map((option, index) => {
                          return (
                            <option key={index} value={option}>
                              {t(option)}
                            </option>
                          );
                        })}
                      </select>

                      <input
                        className="form-row-table-entity"
                        style={{ width: "25%" }}
                        value={item.quadrature}
                        onChange={(e) =>
                          updateQuadratureItem(
                            index,
                            "quadrature",
                            e.target.value
                          )
                        }
                      />
                      <input
                        className="form-row-table-entity"
                        style={{ width: "15%" }}
                        value={item.coeficient}
                        onChange={(e) =>
                          updateQuadratureItem(
                            index,
                            "coeficient",
                            e.target.value
                          )
                        }
                      />
                      <button
                        className="form-row-table-entity"
                        onClick={() => removeQuadratureItem(index)}
                        style={{ width: "10%" }}
                        type="button"
                      >
                        Izbriši
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="form-row">
              <h5>Slike apartmana</h5>
            </div>
            <div className="form-row">
              <div className="form-row-item">
                <label htmlFor="images">Uploadaj slike/u</label>
                <input
                  type="file"
                  id="images"
                  multiple
                  onChange={uploadImages}
                />
              </div>
              <div className="form-row-item">
                <label htmlFor="images-tag">Odaberi prostoriju slika/e</label>
                <select
                  id="images-tag"
                  value={selectedTag}
                  onChange={(e) => setSelectedTag(e.target.value)}
                >
                  {imageTagsOptions.map((option, index) => {
                    return (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-row-item">
                <button
                  className="btn black"
                  type="button"
                  onClick={addImagesWithTags}
                >
                  {uploadingImages ? <LoadingSpinner /> : "Dodaj slike"}
                </button>
              </div>
            </div>
            {imageTagsOptions.map((tag, index) => {
              return (
                <>
                  <div className="form-row">
                    <h5>{tag}</h5>
                  </div>
                  <div className="form-row">
                    <div className="form-row-images">
                      {activeApartment.images
                        .filter((image) => image.tag === tag)
                        .map((image, index) => {
                          return (
                            <div key={index} className="form-row-image">
                              <img src={image.url} alt={tag} />
                              <button
                                type="button"
                                className="delete-image-btn"
                                onClick={() => deleteImage(image)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18 18 6M6 6l12 12"
                                  />
                                </svg>
                              </button>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </>
              );
            })}
            <div className="form-row">
              <h5>Plan apartmana</h5>
            </div>
            <div className="form-row">
              <div className="form-row-item floor-plan">
                <label htmlFor="floor-plan">Plan apartmana</label>
                <input type="file" id="floor-plan" onChange={uploadFloorPlan} />
              </div>
              {activeApartment.floorPlan && (
                <div className="form-row-item floor-plan">
                  <div className="form-row-images">
                    <div className="form-row-image">
                      <img
                        src={activeApartment.floorPlan}
                        alt="Plan apartmana"
                        style={{ width: "100%" }}
                      />
                      <button
                        type="button"
                        className="delete-image-btn"
                        onClick={() => {
                          setActiveApartment({
                            ...activeApartment,
                            floorPlan: "",
                          });
                          deleteImageApi(activeApartment.floorPlan);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {uploadingFloorPlan && (
                <div className="form-row-item floor-plan">
                  <LoadingSpinner />
                </div>
              )}
            </div>
            <div className="form-row">
              <button
                type="submit"
                className="btn black submit"
                disabled={uploadingImages || uploadingFloorPlan}
              >
                {uploadingImages || uploadingFloorPlan ? (
                  <LoadingSpinner />
                ) : activeApartment._id ? (
                  "Uredi apartman"
                ) : (
                  "Dodaj apartman"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Apartments;
