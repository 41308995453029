import React, { useRef, useEffect, useState } from "react";
import { useAppContext } from "../AppContext";
import PageTitle from "../components/PageTitle";
import DatePicker from "react-datepicker";
import { format, parse, parseISO, set } from "date-fns";
import { hr, se } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/Page.scss";
import { serverUrl } from "../utils/domainName";
import LoadingSpinner from "../components/LoadingSpinner";
import NewsTable from "../components/NewsTable";

const News = () => {
  const { news, setNews } = useAppContext();
  const [uploadingImages, setUploadingImages] = useState(false);
  const [activeNews, setActiveNews] = useState({
    title: {
      hr: "",
      en: "",
    },
    content: {
      hr: "<div></div>",
      en: "<div></div>",
    },
    description: {
      // Corrected from 'descritpion'
      hr: "",
      en: "",
    },
    images: [],
    date: "",
  });

  useEffect(() => {
    fetchNews();
  }, []);

  const activeItemtRef = useRef();

  useEffect(() => {
    activeItemtRef.current.scrollIntoView({ behavior: "smooth" });
  }, [activeNews?._id]);

  const fetchNews = () => {
    fetch(`${serverUrl}api/news`)
      .then((res) => res.json())
      .then((data) => {
        setNews(data);
      })
      .catch((err) => {
        fetchNews();
      });
  };

  const addNewFunction = () => {
    setActiveNews({
      title: {
        hr: "",
        en: "",
      },
      content: {
        hr: "<div></div>",
        en: "<div></div>",
      },
      description: {
        // Corrected from 'descritpion'
        hr: "",
        en: "",
      },
      images: [],
      date: "",
    });
  };

  const searchFunction = () => {
    console.log("Search news");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token");

      if (activeNews._id) {
        fetch(`${serverUrl}api/news/${activeNews._id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(activeNews),
        })
          .then((res) => res.json())
          .then((data) => {
            const updatedNews = news.map((news) => {
              if (news._id === data._id) {
                return data;
              }
              return news;
            });

            setNews(updatedNews);
            setActiveNews({
              title: {
                hr: "",
                en: "",
              },
              content: {
                hr: "<div></div>",
                en: "<div></div>",
              },
              description: {
                // Corrected from 'descritpion'
                hr: "",
                en: "",
              },
              images: [],
              date: "",
            });
          })
          .catch((err) => {
            console.error("Error updating news:", err);
          });
      } else {
        fetch(`${serverUrl}api/news`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(activeNews),
        })
          .then((res) => res.json())
          .then((data) => {
            setNews([...news, data]);
            setActiveNews({
              title: {
                hr: "",
                en: "",
              },
              content: {
                hr: "<div></div>",
                en: "<div></div>",
              },
              description: {
                // Corrected from 'descritpion'
                hr: "",
                en: "",
              },
              images: [],
              date: "",
            });
          })
          .catch((err) => {
            console.error("Error creating news:", err);
          });
      }
    } catch (error) {
      console.error("Date parsing error:", error);
    }
  };

  const deleteImage = (image) => {
    const updatedImages = activeNews.images.filter(
      (img) => img.url !== image.url
    );
    setActiveNews({
      ...activeNews,
      images: updatedImages,
    });
    deleteImageApi(image.url);
  };

  const deleteImageApi = (url) => {
    fetch(url, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {})
      .catch((err) => {
        deleteImageApi(url);
      });
  };

  const deleteNews = (news) => {
    const token = localStorage.getItem("token");
    fetch(`${serverUrl}api/news/${news._id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        fetchNews();
      })
      .catch((err) => {
        alert("Greška prilikom brisanja novosti, pokušajte ponovno.");
      });
  };

  const editNews = (news) => {
    const date = news.date ? parseISO(news.date) : null;

    setActiveNews({
      ...news,
      date: date ? format(date, "yyyy-MM-dd") : "",
    });
  };

  const uploadImages = (e) => {
    e.preventDefault();
    setUploadingImages(true);
    const files = e.target.files;

    if (files.length === 0) {
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]); // Make sure the field name matches 'files' in your Multer configuration
    }

    fetch(`${serverUrl}api/upload`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setUploadingImages(false);
        const links = data.keys.map((key) => {
          return `${serverUrl}api/upload/${key}`;
        });
        //  add with images[i].url
        const images = links.map((link) => {
          return { url: link };
        });
        setActiveNews({
          ...activeNews,
          images: [...activeNews.images, ...images],
        });
        console.log("Images uploaded", activeNews.images);
      })
      .catch((err) => {
        alert(
          "Greška prilikom uploada slika, pokušajte ponovno ili probajte smanjiti veličinu slika."
        );
      });
  };

  return (
    <main className="page news-page">
      <div className="items">
        <PageTitle
          title="Novosti"
          description="Pregledajte, Dodajte i uređujte novosti koji su trenutno dostupni na stranici."
          addNewFunction={addNewFunction}
          searchFunction={searchFunction}
        />
        <NewsTable
          items={news}
          editFunction={editNews}
          deleteFunction={deleteNews}
        />
      </div>
      <div className="active-item">
        <div className="active-item-container" ref={activeItemtRef}>
          <div className="active-item-hero">
            <h4>{activeNews.title.hr ? activeNews.title.hr : "Naslov"}</h4>

            {activeNews.images.length > 0 ? (
              <img src={activeNews.images[0].url} alt={activeNews.title.hr} />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            )}
          </div>
          <form className="form" onSubmit={handleSubmit}>
            <div className="form-row">
              <h5>Osnovne informacije</h5>
            </div>
            <div className="form-row">
              <div className="form-row-item">
                <label htmlFor="title-hr">Naslov {"(hr)"}</label>
                <input
                  type="text"
                  id="title-hr"
                  placeholder="Unesite naslov (hr)"
                  value={activeNews.title.hr}
                  onChange={(e) =>
                    setActiveNews({
                      ...activeNews,
                      title: { ...activeNews.title, hr: e.target.value },
                    })
                  }
                />
              </div>
              <div className="form-row-item">
                <label htmlFor="title-en">Naslov {"(en)"}</label>
                <input
                  type="text"
                  id="title-en"
                  placeholder="Unesite naslov (en)"
                  value={activeNews.title.en}
                  onChange={(e) =>
                    setActiveNews({
                      ...activeNews,
                      title: { ...activeNews.title, en: e.target.value },
                    })
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-row-item">
                <label htmlFor="date-built-ends">Datum</label>
                <DatePicker
                  selected={
                    activeNews.date
                      ? parse(activeNews.date, "yyyy-MM-dd", new Date())
                      : null
                  }
                  onChange={(date) =>
                    setActiveNews({
                      ...activeNews,
                      date: date ? format(date, "yyyy-MM-dd") : "",
                    })
                  }
                  dateFormat="dd/MM/yyyy"
                  locale={hr}
                  id="date-built-ends"
                  placeholderText="Odaberite datum"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-row-item">
                <label htmlFor="description-hr">Opis {"(hr)"}</label>{" "}
                {/* Corrected from 'descritpion-hr' */}
                <textarea
                  type="text"
                  id="description-hr" // Corrected from 'descritpion-hr'
                  value={activeNews.description.hr} // Corrected from 'descritpion.hr'
                  placeholder="Unesite opis (hr)"
                  onChange={(e) =>
                    setActiveNews({
                      ...activeNews,
                      description: {
                        // Corrected from 'descritpion'
                        ...activeNews.description, // Corrected from 'descritpion'
                        hr: e.target.value,
                      },
                    })
                  }
                />
              </div>
              <div className="form-row-item">
                <label htmlFor="description-en">Opis {"(en)"}</label>{" "}
                {/* Corrected from 'descritpion-en' */}
                <textarea
                  type="text"
                  id="description-en" // Corrected from 'descritpion-en'
                  value={activeNews.description.en} // Corrected from 'descritpion.en'
                  onChange={(e) =>
                    setActiveNews({
                      ...activeNews,
                      description: {
                        // Corrected from 'descritpion'
                        ...activeNews.description, // Corrected from 'descritpion'
                        en: e.target.value,
                      },
                    })
                  }
                  placeholder="Unesite opis (en)"
                />
              </div>
            </div>

            <div className="form-row">
              <h5>Slike Novosti</h5>
            </div>

            <div className="form-row">
              <div className="form-row-item">
                <label htmlFor="images">Dodaj slike</label>
                <input
                  type="file"
                  id="images"
                  onChange={uploadImages}
                  multiple
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-row-images">
                {activeNews.images.map((image, index) => (
                  <div key={index} className="form-row-image">
                    <img src={image.url} alt={image} />
                    <button
                      type="button"
                      className="delete-image-btn"
                      onClick={() => deleteImage(image)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="form-row">
              <button
                type="submit"
                className="btn black submit"
                disabled={uploadingImages}
              >
                {uploadingImages ? (
                  <LoadingSpinner />
                ) : activeNews._id ? (
                  "Uredi novost"
                ) : (
                  "Dodaj novost"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default News;
