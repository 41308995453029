import React, { useEffect } from "react";
import pozadina from "../assets/images/apartmani.png";
import "../styles/Login.scss";
import logo from "../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { serverUrl } from "../utils/domainName";
import { useAppContext } from "../AppContext";

const Login = () => {
  const { setUser, setApartments, news, setNews } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    // get token from local storage
    // if token exists, redirect to home page
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (token && user) {
      fetchApartments();
      fetchNews();
      navigate("/home");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // handle the form submission
    // example:
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;

    // perform your fetch or axios call here
    // on success, you can set the token in local storage
    // localStorage.setItem("token", "your-token");
    // and then redirect to home page
    // fetch the https://mp-premium-server.vercel.app/api/users/login
    fetch(`${serverUrl}api/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.accessToken) {
          localStorage.setItem("token", data.accessToken);
          localStorage.setItem("user", JSON.stringify(data));
          fetchApartments();
          fetchNews();
          setUser(data);
          navigate("/home");
        } else {
          alert("Invalid credentials");
        }
      })
      .catch((err) => {
        handleSubmit(e);
      });
  };

  const fetchApartments = () => {
    fetch(`${serverUrl}api/apartments`)
      .then((res) => res.json())
      .then((data) => {
        setApartments(data);
        console.log(data);
      })
      .catch((err) => {
        fetchApartments();
      });
  };

  const fetchNews = () => {
    fetch(`${serverUrl}api/news`)
      .then((res) => res.json())
      .then((data) => {
        setNews(data);
      })
      .catch((err) => {
        fetchNews();
      });
  };

  return (
    <main className="login-page">
      <img className="background-image" src={pozadina} alt="Background" />
      <div className="login-form">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <h3>Dobro došli u admin aplikaciju.</h3>
        <p>
          Molimo unesite korisničko ime i lozinku kako biste nastavili. Ukoliko
          nemate račun kontaktirajte administratora.
        </p>
        <form onSubmit={handleSubmit}>
          <input type="email" name="email" placeholder="E-mail" required />
          <input
            type="password"
            name="password"
            placeholder="Lozinka"
            required
          />
          <button type="submit" className="btn">
            Prijavi se
          </button>
        </form>
        <div className="forgot-password">
          <Link to="/forgot-password">Zaboravili ste lozinku?</Link>
        </div>
      </div>
    </main>
  );
};

export default Login;
