import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Apartments from "./pages/Apartments";
import { useTranslation } from "react-i18next";
import "./i18n"; // osigurava da je i18n konfiguriran
import { AppProvider } from "./AppContext";
import News from "./pages/News";
import Login from "./pages/Login";

function App() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <AppProvider>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/apartments" element={<Apartments />} />
          <Route path="/news" element={<News />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
