import React, { useState } from "react";
import "../styles/ItemsTable.scss";

const ApartmentsTable = ({ items, editFunction, deleteFunction }) => {
  const [actionsDropdown, setActionsDropdown] = useState("");

  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  return (
    <div className="items-table">
      <div className="items-table-header">
        <div className="items-table-header-item" style={{ width: "10%" }}>
          <span> Slika </span>
        </div>
        <div className="items-table-header-item" style={{ width: "25%" }}>
          <span> {"Naziv" + "(hr)"} </span>
        </div>
        <div className="items-table-header-item" style={{ width: "45%" }}>
          <span> {"Opis" + "(hr)"} </span>
        </div>
        <div className="items-table-header-item" style={{ width: "10%" }}>
          <span>Kat</span>
        </div>
        <div className="items-table-header-item" style={{ width: "10%" }}>
          <span>Akcije</span>
        </div>
      </div>

      {items.map((item) => (
        <div className="items-table-row" key={item.id}>
          <div className="items-table-row-item" style={{ width: "10%" }}>
            <img src={item.floorPlan} alt={item.title.hr} />
          </div>
          <div className="items-table-row-item" style={{ width: "25%" }}>
            <span>{item.title.hr}</span>
          </div>
          <div className="items-table-row-item" style={{ width: "45%" }}>
            <span> {truncateText(item.description.hr, 100)} </span>
          </div>
          <div className="items-table-row-item" style={{ width: "10%" }}>
            <span>{item.floor}</span>
          </div>
          <div className="items-table-row-item" style={{ width: "10%" }}>
            <button
              className="items-table-actions-button"
              onMouseEnter={() => setActionsDropdown(item._id)}
              onMouseLeave={() => setActionsDropdown("")}
            >
              <div className="items-table-actions-dot"></div>
              <div className="items-table-actions-dot"></div>
              <div className="items-table-actions-dot"></div>

              <div
                className={
                  actionsDropdown === item._id
                    ? "items-table-actions-dropdown active"
                    : "items-table-actions-dropdown"
                }
              >
                <button onClick={() => editFunction(item)}>Uredi</button>
                <button onClick={() => deleteFunction(item.id)}>Izbriši</button>
              </div>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ApartmentsTable;
