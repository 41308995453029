// i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Uvezi ili definiraj prijevode
const resources = {
  en: {
    translation: {
      "ENTRANCE/HALLWAY": "ENTRANCE/HALLWAY",
      "LIVING AREA": "LIVING AREA",
      KITCHEN: "KITCHEN",
      BATHROOM: "BATHROOM",
      BEDROOM: "BEDROOM",
      "BEDROOM 1": "BEDROOM 1",
      "BEDROOM 2": "BEDROOM 2",
      "COVERED TERRACE": "COVERED TERRACE",
      "OPEN TERRACE": "OPEN TERRACE",
      "GREEN AREA": "GREEN AREA",
      "2 PARKING SPOTS": "2 PARKING SPOTS",
    },
  },
  hr: {
    translation: {
      "ENTRANCE/HALLWAY": "ULAZ/HODNIK",
      "LIVING AREA": "DNEVNI BORAVAK",
      KITCHEN: "KUHINJA",
      BATHROOM: "KUPAONICA",
      BEDROOM: "SPAVAĆA SOBA",
      "BEDROOM 1": "SPAVAĆA SOBA 1",
      "BEDROOM 2": "SPAVAĆA SOBA 2",
      "COVERED TERRACE": "NATKRIVENA TERASA",
      "OPEN TERRACE": "OTVORENA TERASA",
      "GREEN AREA": "ZELENA POVRŠINA",
      "2 PARKING SPOTS": "2 PARKIRNA MJESTA",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "hr",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
